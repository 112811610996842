import React, { FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { SpaceProps } from '@wh-components/system/space'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { RenderSlotId, renderSlotLayoutSizeMapping, renderSlotShowAdvertisingStringMapping } from '@wh/common/digital-advertising/config'
import { Box } from '@wh-components/core/Box/Box'
import { werbungPlaceholderEmptyAfter } from '@wh/common/digital-advertising/components/werbungPlaceholderEmptyAfter'
import { LayoutProps } from '@wh-components/system/layout'

interface ResultListContentAdRenderSlotProps extends SpaceProps, LayoutProps {
    renderSlotId: RenderSlotId
    loadingHeight?: number
}

// this render slot has size 0 when no ad was loaded
export const ResultListContentAdRenderSlot: FunctionComponent<ResultListContentAdRenderSlotProps> = ({
    renderSlotId,
    loadingHeight,
    ...props
}) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const isClosedAdSlot = ((advertisingState.pageModifications.closedAdSlots || []) as string[]).includes(renderSlotId)

    // display:flex implicitly makes child elements display:block, which is necessary to avoid the extra spacing
    return (
        <Box
            id={renderSlotId}
            alignItems="center"
            justifyContent="center"
            position="relative"
            borderBottom="owl"
            aria-hidden="true"
            zIndex="content"
            {...props}
            css={css`
                iframe {
                    /* avoids extra spacing at the bottom */
                    display: block;
                }

                box-sizing: content-box;
                display: none;

                ${(p) => p.theme.media.only.phone} {
                    display: ${isClosedAdSlot ? 'none' : renderSlotLayoutSizeMapping[renderSlotId] === 'phone' ? 'flex' : 'none'};
                }

                ${(p) => p.theme.media.only.tablet} {
                    display: ${isClosedAdSlot ? 'none' : renderSlotLayoutSizeMapping[renderSlotId] === 'tablet' ? 'flex' : 'none'};
                }

                ${(p) => p.theme.media.desktop} {
                    display: ${isClosedAdSlot ? 'none' : renderSlotLayoutSizeMapping[renderSlotId] === 'desktop' ? 'flex' : 'none'};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }

                ${emptyDuringLoad(loadingHeight)}

                /* defines :before */
                ${renderSlotShowAdvertisingStringMapping[renderSlotId] ? werbungBeforeCss : null}

                /* defines :after */
                ${loadingHeight ? werbungPlaceholderEmptyAfter(loadingHeight) : null}

                /* reduces page jumping when content ad is vertically topmost when it is loaded and its height changes since scroll anchoring will now use the next ad row instead */
                overflow-anchor: none;
            `}
        />
    )
}

const werbungBeforeCss = css`
    &:not(:empty):before {
        content: 'WERBUNG';
        position: absolute;
        left: ${(p) => p.theme.space.m}px;
        bottom: ${(p) => p.theme.space.xs}px;
        font-size: 8px;
        color: ${(p) => p.theme.colors.palette.koala};
    }
`

const emptyDuringLoad = (loadingHeight: number | undefined) => {
    if (!loadingHeight) {
        return css`
            /* hide ad slot during loading of advertising, so the padding and margin are also not visible during that time which would look weird */
            /* INFO: we do not use display:none because that breaks the current Lazy Load implementation of the DA lib, since it polls the position of the render slot to decide whether to load it, and a display:none render slot has a rect of (0,0,0,0) - see isVisible function: https://gitlab.willhaben.at/willhaben/tribe/search/digital-advertising/-/blob/main/frontend/frontend-library/src/util/RenderSlotUtil.ts#L75 */
            &:empty {
                padding: 0;
                border: 0;

                &::before {
                    display: none;
                }
            }
        `
    } else {
        return css`
            /* set height only during loading, afterwards should jump to size of Advertisement */
            &:empty {
                height: ${loadingHeight ? `${loadingHeight}px` : 'auto'};
            }
        `
    }
}
